import {Editor} from '@tinymce/tinymce-react';
import 'tinymce/plugins/code';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/help';
import 'tinymce/plugins/link';
import 'tinymce/plugins/template';
import api from '../../api';

const contentStyle = `
body {
  font-family:Helvetica,Arial,sans-serif;
  font-size:14px;
  }
.tpl-var {
  background-color: #c00;
  color:#fff;
  font-weight: bold;
  padding: 3px;
  border-radius:5px
  }`;

const toolbar = `undo redo | formatselect | 
'bold italic backcolor | alignleft aligncenter 
'alignright alignjustify | bullist numlist outdent indent | 
'removeformat | link | template`;

const RichEditor = ({onChange, initialValue, type = 'invitation'}) => {

  let templates = [];

  if (type === 'confirm') {
    templates = [
      {
        title: 'Schöne Grüße',
        description: '',
        content: '<span class="tpl-var">{{ greetings }}</span>'
      },
      {
        title: 'Programmpunkte',
        description: '',
        content: '<span class="tpl-var">{{ program }}</span>'
      },
      {
        title: 'Vollständige Adresse',
        description: '',
        content: '<span class="tpl-var">{{ full_address }}</span>'
      },
      {
        title: 'Firma',
        description: '',
        content: '<span class="tpl-var">{{ company }}</span>'
      },
      {
        title: 'Telefon',
        description: '',
        content: '<span class="tpl-var">{{ phone }}</span>'
      },
      {
        title: 'E-mail',
        description: '',
        content: '<span class="tpl-var">{{ email }}</span>'
      },
      {
        title: 'Adresse',
        description: '',
        content: '<span class="tpl-var">{{ address }}</span>'
      },
      {
        title: 'PLZ',
        description: '',
        content: '<span class="tpl-var">{{ post_index }}</span>'
      },
      {
        title: 'Ort',
        description: '',
        content: '<span class="tpl-var">{{ area }}</span>'
      },
      {
        title: 'Bundesland',
        description: '',
        content: '<span class="tpl-var">{{ state }}</span>'
      },
    ];
  } else if (type === 'invitation') {
    templates = [
      {
        title: 'Besprechungsname',
        description: '',
        content: '<span class="tpl-var">{{ meeting_name }}</span>'
      },
      {
        title: 'Mitgliedsname',
        description: '',
        content: '<span class="tpl-var">{{ member_name }}</span>'
      },
      {
        title: 'URL zum Formular',
        description: '',
        content: '<span class="tpl-var">{{ meeting_url }}</span>'
      },
      {
        title: 'Vollständige Adresse',
        description: '',
        content: '<span class="tpl-var">{{ full_address }}</span>'
      },
      {
        title: 'Firma',
        description: '',
        content: '<span class="tpl-var">{{ company }}</span>'
      },
      {
        title: 'Telefon',
        description: '',
        content: '<span class="tpl-var">{{ phone }}</span>'
      },
      {
        title: 'E-mail',
        description: '',
        content: '<span class="tpl-var">{{ email }}</span>'
      },
      {
        title: 'Adresse',
        description: '',
        content: '<span class="tpl-var">{{ address }}</span>'
      },
      {
        title: 'PLZ',
        description: '',
        content: '<span class="tpl-var">{{ post_index }}</span>'
      },
      {
        title: 'Ort',
        description: '',
        content: '<span class="tpl-var">{{ area }}</span>'
      },
      {
        title: 'Bundesland',
        description: '',
        content: '<span class="tpl-var">{{ state }}</span>'
      },
    ];
  }

  return <Editor
    initialValue={initialValue}
    onEditorChange={onChange}
    init={{
      language: 'de',
      height: 700,
      menubar: true,
      plugins: [
        'fullscreen', 'help', 'template', 'link', 'code',
      ],
      file_picker_types: 'file',
      file_picker_callback: (cb, value, meta) => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        const formData = new FormData();
        input.addEventListener('change', (e) => {
          const file = e.target.files[0];
          formData.append("file", file);
          api.upload.upload(file.name, formData).then(data => {
            cb(data.data.file, {title: file.name});
          });
        });

        input.click();
      },
      templates: templates,
      toolbar: toolbar,
      content_style: contentStyle,
    }}
  />
}

export default RichEditor;