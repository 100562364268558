import axios from '../axios';

const getPDF = url => ({filename = 'invoice.pdf', params}) => axios({
  url: url,
  method: 'GET',
  params: params,
  responseType: 'blob',
}).then((response) => {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
});

const endpoints = {
  invoiceList: (params) => axios.get(`/invoice/invoice/`, {params}),
  invoiceRetrieve: id => axios.get(`/invoice/invoice/${id}/`),
  invoiceDelete: id => axios.delete(`/invoice/invoice/${id}/`),
  invoiceCreate: ({meetingId}) => axios.post(`/invoice/create/${meetingId}/`),
  invoicePDF: id => getPDF(`/invoice/pdf/${id}`),
  invoiceAllPDF: getPDF(`/invoice/pdf2/`),
  invoiceSend: ({invoiceId}) => axios.patch(`/invoice/send/${invoiceId}/`),
};

export default endpoints;